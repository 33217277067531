import React from 'react';
import Link from 'gatsby-link';
import userConfig from '../../../config';
import md5 from "react-native-md5"
import Wrapper from './Wrapper';

function HeaderImage() {
    let emailHash = md5.hex_md5( userConfig.email);
    let imgSrc = 'https://www.gravatar.com/avatar/' + emailHash + "?s=200";
  return (
    <Wrapper>
      <Link to="/">
        <img src={imgSrc} alt={userConfig.author} />
      </Link>
    </Wrapper>
  );
}

export default HeaderImage;
