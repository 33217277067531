module.exports = {
  title: 'About me', // Required
  author: 'Richard Spenceley', // Required
  email: 'richard@spenceley.org.uk',
  description: 'Scrum Master / Product Owner',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    github: 'https://github.com/spence1115',
    twitter: 'https://twitter.com/spence1115',
    linkedin: 'https://www.linkedin.com/in/richardspenceley/',
  },
  siteUrl: 'https://spenceley.org.uk',
};
